@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background-color: #f8f8f8;
  color: #333; /* Text color */
}

/* Apply a subtle shadow to the entire page for depth */
body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.03) 100%);
  pointer-events: none;
  z-index: -1;
}

@media (max-width: 768px) {
  body{
    font-size: 14px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* scrollToTop.css */
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff !important;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  z-index: 999;
}

.scroll-to-top:hover {
  background-color: #0056b3;
}

.fill-screen{
  min-height: 100vh;
}